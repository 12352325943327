import { Box, Button, Card, CardContent, Typography, CardMedia, CardHeader, Collapse } from '@mui/material';
import dayjs from 'dayjs';
import parse from 'html-react-parser';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Colors } from 'src/shared/components/styles/colors';
import NewsActionLikeComment from 'src/modules/home/components/newsActionLikeComment';
import { useTranslation } from 'react-i18next';
import { NewsResponse } from 'src/services/api/response/newsResponse';
import { RootStateType } from 'src/services/redux/reducers';
import NotificationDot from 'src/shared/components/atoms/notificationDot';
import { UserTypeEnum } from 'src/shared/enums/userType.enum';
import { NewsCategoryEnum } from 'src/shared/enums/newsCategory.enum';
import { useGlobals } from 'src/services/contexts/appContext';
import NewsEditButton from 'src/modules/home/components/newsEditButton';
import CommentsList from 'src/modules/home/components/commentsList';
import NewsCommentInput from 'src/modules/home/components/newsCommentInput';
import { useMarkAsRead } from 'src/modules/home/hooks/mutations/useMarkNewsAsRead';
import { PrioritySymbol } from 'src/shared/components/atoms/prioritySymbol';
import activityGif from '../../../assets/images/calendar.gif';
import newsPaperGif from '../../../assets/images/newspaper.gif';
import NewsDetailListItem from './newsDetailListItem';

export const htmlParserOptions = (user: any, domain: string) => {
  return {
    replace: ({ attribs, name }: any) => {
      if (!attribs) {
        return;
      }
      // Länkar
      if (name === 'a') {
        const { href } = attribs;
        if (href?.includes(`${domain}.easystaff.se/media/`)) {
          // eslint-disable-next-line no-param-reassign
          attribs.href = `${href}?access_token=${user?.access_token}`;
        }
        // eslint-disable-next-line no-param-reassign
        attribs.style = `color: ${Colors.ACCENT_DARK}`;
      }
      // Bilder
      if (name === 'img') {
        // eslint-disable-next-line no-param-reassign
        attribs.style = `max-width: 614px; height: auto;`;
      }
    },
  };
};

type NewsListItemProps = {
  newsItem: NewsResponse;
  isActive: boolean;
  modalOpen: boolean;
  setModalOpen: (arg: boolean) => void;
  setActiveNewsItemId: (newsItemId: string) => void;
  unPublish: () => void;
};

const NewsListItem = (props: NewsListItemProps) => {
  const { newsItem, isActive, setActiveNewsItemId, unPublish, modalOpen, setModalOpen } = props;
  const { t } = useTranslation(['common', 'news']);

  // Global States
  const { loggedInUser } = useGlobals();
  const { domain } = useSelector((state: RootStateType) => state.appState);
  const { user } = useSelector((state: RootStateType) => state.oidc);

  // useMutations
  const markAsRead = useMarkAsRead();

  // Local States
  const [imageError, setImageError] = useState(false);
  const [showComments, setShowComments] = useState(false);
  const [readMore, setReadMore] = useState<boolean>(false);

  const onClickOpenNews = () => {
    setActiveNewsItemId(newsItem?.GUID);
    if (newsItem.ReadStatus === false) {
      // Markera nyheten som läst
      markAsRead.mutate(newsItem.GUID);
    }
    setReadMore(true);
  };
  return (
    <>
      <Card
        raised
        sx={{
          mb: 2,
          p: 0,
          mx: isActive ? '-30px' : 0,
          transition: 'all .3s ease-in-out',
        }}
      >
        <Box sx={{ py: 0 }}>
          <CardHeader
            sx={{ pt: 2, pr: 2, pl: 2 }}
            title={
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant='h3' sx={{ fontSize: '24px' }}>
                  {newsItem.Heading}
                </Typography>
                {!newsItem.ReadStatus && !newsItem.ShowConfirmAction && <NotificationDot />}
                {newsItem.ShowConfirmAction && !newsItem.ConfirmedByMe && <PrioritySymbol />}
              </Box>
            }
            subheader={
              <>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'left',
                    alignItems: 'center',
                    gap: '5px',
                  }}
                >
                  <Typography
                    variant='subtitle1'
                    sx={{
                      color: Colors.GRAY_DARK,
                      paddingRight: '5px',
                      fontSize: '14px',
                    }}
                  >
                    {dayjs(newsItem?.PublishedDate).format('D MMM YYYY')}
                  </Typography>
                  <Typography
                    variant='subtitle1'
                    sx={{
                      display: 'flex',
                      color: Colors.GRAY_DARK,
                      alignItems: 'center',
                    }}
                  >
                    <img width='14px' src={newsItem?.Category === NewsCategoryEnum.Activities ? activityGif : newsPaperGif} alt='loading...' />
                  </Typography>
                  <Typography variant='subtitle1' sx={{ color: Colors.GRAY_DARK, fontSize: '14px' }}>
                    {t(`dropdown.${newsItem?.Category}`)}
                  </Typography>
                </Box>

                <Collapse
                  in={isActive}
                  timeout={300}
                  easing={{
                    enter: 'cubic-bezier(0.4, 0.0, 0.2, 1)',
                    exit: 'linear',
                  }}
                  unmountOnExit
                >
                  {newsItem !== undefined && loggedInUser?.UserType === UserTypeEnum.Admin && (
                    <NewsEditButton newsItem={newsItem} unPublish={unPublish} modalOpen={modalOpen} setModalOpen={setModalOpen} />
                  )}
                </Collapse>
              </>
            }
          />
          {newsItem?.Image && !imageError && (
            <CardMedia
              sx={{
                width: '100%',
                // transition: 'all .3s linear',
                maxHeight: isActive ? '700px' : '586px',
                objectFit: isActive ? 'contain' : 'cover',
                mb: 2,
              }}
              image={`${newsItem?.Image}?access_token=${user?.access_token}`}
              loading='lazy'
              component='img'
              onError={() => {
                setImageError(true);
              }}
            />
          )}
          <CardContent sx={{ py: 0, px: 2 }}>
            {newsItem.Teaser && (
              <Box sx={{ display: 'flex' }}>
                <Typography variant='h4' sx={{ fontSize: '20px' }}>
                  {newsItem.Teaser}
                </Typography>
              </Box>
            )}
            <Box
              className={isActive ? '' : 'fade-out'}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
              }}
            >
              <Typography sx={{ fontSize: '16px' }} variant='body2' component='div' className={isActive ? '' : 'fiveRowsEllipsis'}>
                {parse(newsItem?.BodyText ?? '', htmlParserOptions(user, domain))}
              </Typography>
            </Box>
            {!isActive && (
              <Button
                sx={{
                  ml: -1,
                  textTransform: 'none',
                  fontSize: '16px',
                }}
                onClick={() => onClickOpenNews()}
              >
                {t('news:readMore')}
              </Button>
            )}
          </CardContent>

          {readMore && <NewsDetailListItem isActive={isActive} newsGuid={newsItem.GUID} />}

          {newsItem && (
            <Box px={2} py={2}>
              <Box id='comments'>
                <NewsActionLikeComment
                  item={newsItem}
                  openComments={() => {
                    if (!isActive) {
                      setShowComments(!showComments);
                    } else {
                      setShowComments(!showComments);
                    }
                  }}
                />
              </Box>
              <Collapse
                in={showComments}
                timeout={300}
                easing={{
                  enter: 'cubic-bezier(0.4, 0.0, 0.2, 1)',
                  exit: 'linear',
                }}
                unmountOnExit
              >
                <NewsCommentInput newsItem={newsItem} />
                <Box>
                  <CommentsList items={newsItem} />
                </Box>
              </Collapse>
            </Box>
          )}
        </Box>
      </Card>
    </>
  );
};

export default NewsListItem;
